import React, { useMemo, useEffect } from 'react'
import { styled } from 'gatsby-theme-stitches/src/config'
import * as Sentry from '@sentry/gatsby'

import PanicDaangni from '../assets/PanicDaangni'
import { errorMessage } from '../utils/errorMessageGlobal'
import useSetSeedDesign from '../hooks/useSetSeedDesign'

const NotFoundPage: React.FC = () => {
  const country = process.env.REACT_APP_COUNTRY_CODE as CountryCode
  const errorMessageByCountry = useMemo(() => errorMessage(country), [country])
  const handleClickCloseButton = () => {
    window.location.href = `https://www.daangn.com`
  }

  useEffect(() => {
    Sentry.captureMessage(`Page Not Found - ${window.location.pathname}`)
  }, [])
  useSetSeedDesign()
  return (
    <NotFoundContainer>
      {country === 'kr' && (
        <ImageContainer>
          <PanicDaangni />
        </ImageContainer>
      )}
      <Title>{errorMessageByCountry.title}</Title>
      <Description>{errorMessageByCountry.subTitle}</Description>
      <CloseText onClick={handleClickCloseButton}>
        {errorMessageByCountry.close}
      </CloseText>
    </NotFoundContainer>
  )
}

const NotFoundContainer = styled('div', {
  width: '100%',
  height: '90%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
})

const ImageContainer = styled('div', {
  paddingBottom: 20,
  maxWidth: 300,
  maxHeight: 300,
})

const Title = styled('h2', {
  width: ' 80%',
  fontWeight: 'bold',
  margin: 0,
  wordBreak: 'break-word',
  textAlign: 'center',
})

const Description = styled('p', {
  width: '80%',
  fontSize: '14px',
  margin: 0,
  marginTop: 10,
  wordBreak: 'break-word',
  textAlign: 'center',
})

const CloseText = styled('p', {
  width: '60%',
  fontSize: 18,
  margin: 0,
  marginTop: 10,
  wordBreak: 'break-word',
  textAlign: 'center',
  color: '#ff8a3d',
  cursor: 'pointer',
  '&:hover': {
    fontWeight: 'bold',
  },
})

export default NotFoundPage
